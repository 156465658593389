import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../utils/constant";
import ProTextInput from "../../Components/Inputs/TextField";
import ProMobileField from "../../Components/Inputs/MobileField";
import ProEmailField from "../../Components/Inputs/EmailField";
import ZipField from "../../Components/Inputs/ZipField";
import Layout from "../../Components/Layout";
import { changeMode, changeModeForField, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { errorToast, successToast } from "../../../utils/toastHelper";
import UrlField from "../../Components/Inputs/UrlField";
import { ADD_SPONSOR_INFO, GET_SPONSOR_INFO, UPDATE_SPONSOR_INFO } from "../../../utils/services/apiPath";
import { putApi, postApi, getApi } from "../../../utils/services";

const SponsorInfoFormConfig = {
  firstName: {
    label: "Sponsor's First Name",
    perKey: "firstName",
    rules: { required: "Sponsor's First Name Required!" }
  },
  middleName: {
    label: "Middle Name",
    perKey: "middleName"
  },
  companyName: {
    label: "Company Name",
    perKey: "companyName",
    rules: { required: "Company Name Required!" }
  },
  lastName: {
    label: "Last Name",
    perKey: "lastName"
  },
  address: {
    label: "Address",
    perKey: "address",
    rules: { required: "Address is Required!" }
  },
  address1: {
    label: "Address1",
    perKey: "address1"
  },
  state: {
    label: "State",
    perKey: "state",
    rules: { required: "State is Required!" }
  },
  city: {
    label: "City",
    perKey: "city",
    rules: { required: "City is Required!" }
  },
  zipcode: {
    label: "Zip Code",
    perKey: "zipcode",
    rules: { required: "Zipcode is Required!" }
  },
  telePhoneNumber: {
    label: "Telephone Number",
    perKey: "telePhoneNumber",
    rules: { required: "Telephone Number Required!" }
  },
  cellPhoneNumber: {
    label: "Cellphone Number",
    perKey: "cellPhoneNumber",
    rules: { required: "Cellphone Number Required!" }
  },
  email: {
    label: "Email",
    perKey: "email",
    rules: { required: "Email ID Required!" }
  },
  businessAddress: {
    label: "Address",
    perKey: "businessAddress"
  },
  businessAddress1: {
    label: "Address 1",
    perKey: "businessAddress1"
  },
  businessState: {
    label: "State",
    perKey: "businessState"
  },
  businessCity: {
    label: "City",
    perKey: "businessCity"
  },
  businessZipcode: {
    label: "Zip Code",
    perKey: "businessZipcode"
  },
  contactFirstName: {
    label: "First Name",
    perKey: "contactFirstName",
    rules: { required: "First Name is Required!" }
  },
  contactMiddleName: {
    label: "Middle Name",
    perKey: "contactMiddleName"
  },
  contactLastName: {
    label: "Last Name",
    perKey: "contactLastName"
  },
  contactEmail: {
    label: "Email",
    perKey: "contactEmail",
    rules: { required: "Email is Required!" }
  },
  contactPhoneNumber: {
    label: "Phone Number",
    perKey: "contactPhoneNumber",
    rules: { required: "Phone Number is Required!" }
  },
  sponsorContactEmail: {
    label: "Email",
    perKey: "SponsorContactEmail"
  },
  firmWebPage: {
    label: "Webpage",
    perKey: "firmWebPage"
  }
};

export default function SponsorInfoForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [sponsorInfoData, setSponsorInfoData] = useState({});
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(SponsorInfoFormConfig));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(SponsorInfoFormConfig) });
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const backAction = () => navigate("/sponsor-info");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => (isAddMode ? backAction() : setFormConfig(changeMode()));

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      ...data,
      companyName: data?.companyName,
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
      address: data?.address,
      address1: data?.address1,
      state: data?.state,
      city: data?.city,
      zipcode: data?.zipcode,
      telePhoneNumber: data?.telePhoneNumber,
      cellPhoneNumber: data?.cellPhoneNumber,
      email: data?.email,
      businessAddress: data?.businessAddress,
      businessAddress1: data?.businessAddress1,
      businessState: data?.businessState,
      businessCity: data?.businessCity,
      businessZipcode: data?.businessZipcode,
      contactFirstName: data?.contactFirstName,
      contactMiddleName: data?.contactMiddleName,
      contactLastName: data?.contactLastName,
      contactEmail: data?.contactEmail,
      contactPhoneNumber: data?.contactPhoneNumber,
      firmWebPage: data?.firmWebPage,
      sponsorInformationGuid: id
    };
    const method = isAddMode ? postApi : putApi;

    const { error } = await method(isAddMode ? ADD_SPONSOR_INFO : UPDATE_SPONSOR_INFO, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Sponsor Info ${isAddMode ? "Added" : "Updated"} Successfully`);
    backAction();
  };

  const getSponsorInfoData = async id => {
    const res = await getApi(GET_SPONSOR_INFO + id);
    const data = {
      ...res,
      firstName: res?.firstName,
      middleName: res?.middleName,
      lastName: res?.lastName,
      address: res?.address,
      address1: res?.address1,
      state: res?.state,
      city: res?.city,
      zipcode: res?.zipcode,
      telePhoneNumber: res?.telePhoneNumber,
      cellPhoneNumber: res?.cellPhoneNumber,
      email: res?.email,
      companyName: res?.companyName,
      businessAddress: res?.businessAddress,
      businessAddress1: res?.businessAddress1,
      businessState: res?.businessState,
      businessCity: res?.businessCity,
      businessZipcode: res?.businessZipcode,
      contactFirstName: res?.contactFirstName,
      contactMiddleName: res?.contactMiddleName,
      contactLastName: res?.contactLastName,
      contactEmail: res?.contactEmail,
      contactPhoneNumber: res?.contactPhoneNumber,
      firmWebPage: res?.firmWebPage,
      SponsorContactEmail: res?.SponsorContactEmail
    };
    setSponsorInfoData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  useEffect(() => {
    if (isAddMode) onEdit();
    if (!isAddMode) getSponsorInfoData(id);
  }, [id]);

  const defaultFormProps = { control, formValues: sponsorInfoData, isAddMode, handleReadValueClick };

  return (
    <Layout>
      <SettingFormsLayout
        status={status}
        backAction={backAction}
        title={isAddMode ? "Add Sponsor Info" : " Sponsor Information"}
        backLabel="Go Back Sponsor Info List"
        beingEdited={isEditModeOn}
        hideEdit={false}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <FormSectionWrapper title={"Sponsor's Basic Information"}>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.middleName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={fieldTruePermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.telePhoneNumber} permission={fieldTruePermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.cellPhoneNumber} permission={fieldTruePermission} />
          <ProEmailField {...defaultFormProps} {...formConfig.email} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.companyName} permission={fieldTruePermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Address"}>
          <ProTextInput {...defaultFormProps} {...formConfig.address} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.address1} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.state} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.city} permission={fieldTruePermission} />
          <ZipField {...defaultFormProps} {...formConfig.zipcode} permission={fieldTruePermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Business Address"}>
          <ProTextInput {...defaultFormProps} {...formConfig.businessAddress} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessAddress1} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessState} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessCity} permission={fieldTruePermission} />
          <ZipField {...defaultFormProps} {...formConfig.businessZipcode} permission={fieldTruePermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Contact Information"}>
          <ProTextInput {...defaultFormProps} {...formConfig.contactFirstName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactMiddleName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactLastName} permission={fieldTruePermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.contactPhoneNumber} permission={fieldTruePermission} />
          <ProEmailField {...defaultFormProps} {...formConfig.contactEmail} permission={fieldTruePermission} />
          <UrlField {...defaultFormProps} {...formConfig.firmWebPage} permission={fieldTruePermission} />
        </FormSectionWrapper>
      </SettingFormsLayout>
    </Layout>
  );
}
