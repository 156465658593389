import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../../Components/ProGrid/components/DeleteCell.scss";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProTextInput from "../../../Components/Inputs/TextField";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { changeMode, changeModeForField, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import EmailField from "../../../Components/Inputs/EmailField";
import MobileField from "../../../Components/Inputs/MobileField";
import {
  ADD_CLIENT_USER,
  DELETE_CLIENT_USER,
  GET_ALL_CLIENT_USERS,
  GET_ALL_ROLE_TYPE,
  RESEND_VERIFYLINK_USER,
  UPDATE_CLIENT_USER
} from "../../../../utils/services/apiPath";
import { deleteApi, getApi, postApi, putApi } from "../../../../utils/services";
import { encryptText } from "../../../../utils/crypto";
import ProSelectField from "../../../Components/Inputs/SelectField";
import EmailCell from "../../../Components/ProGrid/components/EmailCell";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";

const clientUsersFormConfing = {
  firstName: {
    label: "First Name",
    rules: { required: "First Name is required!" }
  },
  lastName: {
    label: "Last Name",
    rules: { required: "Last Name is required!" }
  },
  email: {
    label: "Email",
    rules: { required: "Email is required!" }
  },
  phone: {
    label: "Phone Number",
    rules: { required: "Phone Number is required!" }
  },
  userName: {
    label: "User Name",
    rules: { required: "User Name is required!" }
  },
  password: {
    label: "Password",
    rules: { required: "Password is required!" }
  },
  roleTypeGuid: { label: "Role Type", rules: { required: "Role Type is required!" } },
  isVerified: { label: "Email Verified" }
};

export default function ClientUsers() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(clientUsersFormConfing));
  const [clientUsersData, setClientUsersData] = useState({});
  const [isAddMode, setIsAddMode] = useState(false);
  const [roleType, setRoleType] = useState([]);

  const {
    currentPage,
    pageSize,
    data: usersData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} onClick={onUserClick} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "phone",
        headerName: "Phone Number"
      },
      {
        field: "userName",
        headerName: "User Name"
      },
      {
        field: "isVerified",
        headerName: "Is Verified",
        width: 80,
        renderCell: params => {
          const fillColor = params.row.isVerified ? "green" : "red";
          const title = params.row.isVerified ? "Email Verified" : "Email is Pending";
          return (
              <EmailCell fill={fillColor} params={params} onSend={onSend} title={title}/>
          );
        }
      },
      {
        field: "clientUserGuild",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const { handleSubmit, control, setValue, clearErrors } = useForm({ defaultValues: prepareDefaultValues(clientUsersFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => setFormConfig(changeMode("edit"));
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setShowGrid(true);
    setClientUsersData({});
    clearErrors();
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
  };

  const onCancel = () => {
    resetForm();
    clearErrors();
  };

  const onSend = async id => {
    const { error } = await postApi(RESEND_VERIFYLINK_USER, {}, { params: { ClientUserId: id } });
    if (error) return errorToast(error);
    getAllClientUsers();
    setShowGrid(true);
    successToast("Email sent successfully.");
  };

  const onUserClick = params => {
    setShowGrid(false);
    getClientUserData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };

  const onSave = async data => {
    const { clientUserGuid, password } = clientUsersData;
    const payload = {
      ...data,
      password: encryptText(data.password) || password,
      clientGuid: id,
      clientUserGuid: clientUserGuid ?? null
    };
    const methodApi = clientUserGuid ? putApi : postApi;
    const { error } = await methodApi(clientUserGuid ? UPDATE_CLIENT_USER : ADD_CLIENT_USER, payload);
    if (error) return errorToast(error);
    getAllClientUsers();
    resetForm();
    successToast(`Client user ${clientUserGuid ? "Updated" : "Added"} Successfully`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_CLIENT_USER, { params: { ClientUserId: id } });
    if (error) return errorToast(error);
    getAllClientUsers();
    setShowGrid(true);
    successToast("User deleted successfully.");
  };

  const getClientUserData = data => {
    setClientUsersData(data);
    setIsAddMode(false);
    Object.keys(formConfig).forEach(field => setValue(field, field === "password" ? null : data[field]));
  };

  const getAllClientUsers = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "firstName",
      ...sortModel
    };
    const { data, error, totalRecords } = await postApi(GET_ALL_CLIENT_USERS, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const fetchAllRoleType = async () => {
    const res = await getApi(`${GET_ALL_ROLE_TYPE}/${id}`);
    setRoleType(res?.map(item => ({ label: item.label, value: item.value })));
  };

  useEffect(() => {
    fetchAllRoleType();
  }, [id]);

  useEffect(() => {
    getAllClientUsers();
  }, [getAllClientUsers]);

  const defaultFormProps = { control, formValues: clientUsersData, isAddMode, handleReadValueClick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Users"}
      backLabel={"Go back to Users"}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
    >
      {showGrid && (
        <ProGrid
          title="Users"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.clientUserGuid,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          hideAddButton={false}
          hideExport={false}
          rows={usersData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add User"
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={fieldTruePermission} />
          <EmailField {...defaultFormProps} {...formConfig.email} permission={fieldTruePermission} />
          <MobileField {...defaultFormProps} {...formConfig.phone} permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.roleTypeGuid} options={roleType} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.userName} permission={fieldTruePermission} />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.password}
            type="password"
            {...(clientUsersData?.password ? { rules: {} } : {})}
            permission={fieldTruePermission}
          />
          {clientUsersData?.clientUserGuid && <ProCheckbox {...defaultFormProps} {...formConfig.isVerified} />}
        </>
      )}
    </TabGridAndFormLayout>
  );
}
