export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_V2_URI = process.env.REACT_APP_API_V2_URL;
export const BASE_API_URI = process.env.REACT_APP_API_URL;
export const PANDADOC_API_URI = process.env.REACT_APP_PANDADOC_API_URL;
export const BASE_PASS_KEY = process.env.REACT_APP_PASS_KEY;
export const BASE_SSN_KEY = process.env.REACT_APP_SSN_KEY;
export const PANDADOC_API_KEY = process.env.REACT_APP_PANDADOC_API_KEY;
export const ETA671_TEMPLATE_UUID = process.env.REACT_APP_ETA671_TEMPLATE_UUID;
export const APPENDIXB_TEMPLATE_UUID = process.env.REACT_APP_APPENDIXB_TEMPLATE_UUID;
export const APPENDIXD_TEMPLATE_UUID = process.env.REACT_APP_APPENDIXD_TEMPLATE_UUID;
export const PROSCORE_ADMIN_EMAIL = process.env.REACT_APP_PROSCORE_ADMIN_EMAIL;
// export const PROSCORE_ADMIN_FIRST_NAME = process.env.REACT_APP_PROSCORE_ADMIN_FIRST_NAME;
// export const PROSCORE_ADMIN_LAST_NAME = process.env.REACT_APP_PROSCORE_ADMIN_LAST_NAME;
export const BASE_MUI_KEY = process.env.REACT_APP_MUI_KEY;
export const BASE_IDENTITY_API_URL = process.env.REACT_APP_IDENTITY_API_URL;
export const USE_IDENTITY = process.env.REACT_APP_USE_IDENTITY;
export const IDENTITY_CLIENT_ID = process.env.REACT_APP_IDENTITY_CLIENT_ID;

export const DEFAULT_SIZE = 4;
export const STAUTES = Object.freeze({
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  IDLE: "idle"
});
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;

export const DETAULT_ROLE = "Admin";
export const EMPLOYEE_ROLE = "Employee";
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_TIME_FORMAT = "hh:mm A";
export const DEFAULT_DATE_PAYLOAD_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_ZONE = "Europe/London";
