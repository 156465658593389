import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { changeMode, changeModeForField, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProSelectField from "../../../Components/Inputs/SelectField";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { getApi, patchApi } from "../../../../utils/services";
import { ADMIN_CLIENTS, APPRENTICE_MANAGEMENT_OPTION, APPRENTICE_MANAGEMENT_TYPE } from "../../../../utils/services/apiPath";
import { STAUTES } from "../../../../utils/constant";

const apprenticeOptionConfig = {
  apprenticeOption: {
    label: "Apprentice Option"
  }
};

export default function ApprenticeshipOptions() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(apprenticeOptionConfig, "read"));
  const [apprenticeOptionData, setApprenticeOptionData] = useState({});
  const [apprenticeOptions, setApprenticeOptions] = useState([]);
  // const [isSaving, setIsSaving] = useState(false);
  // const [status, setStatus] = useState(STAUTES.IDLE);

  const { handleSubmit, control, setValue, clearErrors } = useForm({ defaultValues: prepareDefaultValues(apprenticeOptionConfig) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const onEdit = () => setFormConfig(changeMode("edit"));

  const onSave = async data => {
    // setIsSaving(true);
    // setStatus(STAUTES.LOADING);
    const url = APPRENTICE_MANAGEMENT_TYPE.replace("{clientGuid}", id)
    const { error } = await patchApi(url, { apprenticeshipManagementTypeGuid: data?.apprenticeOption })
    // setIsSaving(false); setStatus(STAUTES.IDLE);
    if (error) return errorToast("Unable to save apprentice management option.")
    successToast("Updated apprentice management option!")
    navigate(0)
  };

  const fetchApprenticeOptions = async () => {
    const { data, error } = await getApi(APPRENTICE_MANAGEMENT_OPTION);
    if (error) return errorToast("Unable to fetch apprentice options")
    setApprenticeOptions(
      data
    );
  };

  const getSelectedApprenticeOption = async () => {
    try {
      const url = ADMIN_CLIENTS.replace("{clientId}", id)
      const data = await getApi(url)
      console.log(data)
      setApprenticeOptionData({ apprenticeOption: "Apprenticeship 360" });
    } catch (error) {
      errorToast(error);
    }
  };

  const resetForm = () => {
    clearErrors();
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
  };

  const onCancel = () => {
    resetForm();
    clearErrors();
  };

  useEffect(() => {
    fetchApprenticeOptions();
  }, []);

  useEffect(() => {
    getSelectedApprenticeOption();
  }, [id]);

  const defaultFormProps = { control, formValues: apprenticeOptionData, handleReadValueClick };

  return (
    <TabGridAndFormLayout
      showGrid={false}
      title={"Apprenticeship Options"}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
    >
      <ProSelectField {...defaultFormProps} {...formConfig.apprenticeOption} options={apprenticeOptions} permission={fieldTruePermission} />
    </TabGridAndFormLayout>
  );
}
