import DashboardIcon from "../App/Assets/IconComponents/Dashboard";
import Rap from "../App/Assets/IconComponents/Rap";
import Setting from "../App/Assets/IconComponents/Settings";

import{
  R_CLIENT_DOCUMENT_TYPE,
  R_CONTRACT_TYPE
} from "./permissionReferenceConfig";

export const headerMenus = [
  {
    to: "/clients",
    title: "Clients",
    hasArrow: false,
    icon: DashboardIcon
  },
  {
    to: "/rap",
    title: "RAP",
    hasArrow: true,
    icon: Rap,
    subMenus: [
      {
        link: "/sponsor-info",
        title: "Sponsor"
      },
      {
        link: "/job-titles",
        title: "Job Titles"
      },
      {
        link: "/rap-info",
        title: "RAP Info"
      },
      {
        link: "/assign-rapInfo",
        title: "Assign RAP"
      }
    ]
  },
  {
    to: "/apprentice-management",
    title: "Apprentice",
    hasArrow: false,
    icon: DashboardIcon
  },
  {
    to: "/communications",
    title: "Communication",
    hasArrow: true,
    icon: DashboardIcon,
    subMenus: [
      {
        link: "/communication",
        title: "Communication"
      },
      {
        link: "/mentor-apprentice-pairing",
        title: "Mentor Apprentice Messaging"
      }
    ]
  },
  {
    to: "/settings",
    title: "Settings",
    hasArrow: true,
    icon: Setting,
    subMenus: [
      {
        link: "/users",
        title: "Users"
      },
      {
        link: "#",
        title: "User Roles"
      },
      {
        link: "#",
        title: "User Permissions"
      },
      {
        link: "/settings/client-document-type",
        title: "Client Document Type",
        reference: R_CLIENT_DOCUMENT_TYPE
      },
      {
        link: "/settings/contract-type",
        title: "Contract Type",
        reference: R_CONTRACT_TYPE
      }
    ]
  }
];
