import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { changeMode, checkEditEnabled, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { Grid } from "@mui/material";
import ProTextInput from "../../../Components/Inputs/TextField";
import { STAUTES } from "../../../../utils/constant";
import { getApi, postApi, putApi } from "../../../../utils/services";
import { ADD_RAP_RATIO, GET_BYID_RAP_RATIO, UPDATE_RAP_RATIO } from "../../../../utils/services/apiPath";
import { successToast } from "../../../../utils/toastHelper";

const rapRatioFormConfing = {
  ratio: {
    label: "Select Ratio",
    perKey: "ratio",
    rules: { required: "Select Ratio is required!" }
  },
  mentor: {
    label: "Number of Mentor",
    perKey: "mentor"
  },
  apprentice: {
    label: "Number of Apprentice",
    perKey: "apprentice"
  }
};

const ratioDropdown = [
  { label: "1 Mentor to 1 Apprentice", value: "1:1" },
  { label: "2 Mentors to 1 Apprentice", value: "2:1" },
  { label: "3 Mentors to 2 Apprentices", value: "3:2" },
  { label: "4 Mentors to 3 Apprentices", value: "4:3" },
  { label: "Custom", value: "Custom" }
];

export default function RapRatio() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = false;
  const [rapRatio, setRapRatio] = useState({});
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(rapRatioFormConfing, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, watch, setValue, setError, clearErrors } = useForm({ defaultValues: prepareDefaultValues(rapRatioFormConfing) });
  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);
  const [isSaving, setIsSaving] = useState(false);

  const isMentor = watch("mentor");
  const isApprentice = watch("apprentice");
  const watchRatio = watch("ratio");

  useEffect(() => {
    if (watchRatio === "Custom") {
      if (isMentor && (isMentor < 1 || isMentor > 5)) {
        setError("mentor", {
          type: "manual",
          message: "Mentor count must be between 1 and 5."
        });
      } else {
        clearErrors("mentor");
      }

      if (isApprentice && (isApprentice < 1 || isApprentice > 3)) {
        setError("apprentice", {
          type: "manual",
          message: "Apprentice count must be between 1 and 3."
        });
      } else {
        clearErrors("apprentice");
      }
    } else {
      clearErrors("mentor");
      clearErrors("apprentice");
    }
  }, [isMentor, isApprentice, setError, clearErrors]);

  const transformRatioData = data => {
    const mentors = data.numberOfMentors;
    const apprentices = data.numberOfApprentices;

    return {
      ratio: data.isCustom ? "Custom" : mentors && apprentices ? `${mentors}:${apprentices}` : "-",
      mentor: data.isCustom ? mentors : "",
      apprentice: data.isCustom ? apprentices : "",
      rapInfoId: data.rapInfoId,
      rapRatioId: data.rapRatioId
    };
  };

  const onSave = async formData => {
    if (isSaving) return;
    const isCustom = formData.ratio === "Custom" ? true : false;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    if (id) {
      const payload = {
        rapInfoId: id,
        numberOfMentors: isCustom ? Number(formData.mentor) : Number(formData.ratio.split(":")[0]),
        numberOfApprentices: isCustom ? Number(formData.apprentice) : Number(formData.ratio.split(":")[1]),
        isCustom: isCustom
      };
      const data = rapRatio?.rapRatioId
        ? await putApi(`${UPDATE_RAP_RATIO}/${rapRatio?.rapRatioId}`, payload)
        : await postApi(ADD_RAP_RATIO, payload);
      getRapRatios();
      successToast("RAP Ratio created successfully.");
      const ratioData = transformRatioData(data);
      setRapRatio(ratioData);
      setFormConfig(changeMode("read"));
      setIsSaving(false);
      setStatus(STAUTES.IDLE);
    }
  };

  const updateFormFields = (data, formConfig, setValue) => {
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getRapRatios = useCallback(async () => {
    const data = await getApi(`${GET_BYID_RAP_RATIO}/${id}`);
    const ratioData = transformRatioData(data);
    setRapRatio(ratioData);
    updateFormFields(ratioData, formConfig, setValue);
  }, [formConfig, setValue]);

  useEffect(() => {
    if (id) getRapRatios();
  }, [id]);

  const backAction = () => navigate("/apprentice-180/rap");
  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };
  const onCancel = () => {
    setFormConfig(changeMode("read"));
  };

  const defaultFormProps = { control, formValues: rapRatio };

  return (
    <div>
      <ProFormsLayout
        status={status}
        backAction={backAction}
        title={"Ratio"}
        backLabel="Go Back to Rap Info"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSave)}
      >
        <Grid container spacing={2}>
          <ProSelectField {...defaultFormProps} {...formConfig?.ratio} permission={fieldTruePermission} options={ratioDropdown} />
          {watchRatio == "Custom" && (
            <>
              <ProTextInput {...defaultFormProps} {...formConfig.mentor} permission={fieldTruePermission} />
              <ProTextInput {...defaultFormProps} {...formConfig.apprentice} permission={fieldTruePermission} />
            </>
          )}
        </Grid>
      </ProFormsLayout>
    </div>
  );
}
