import {
    R_CLIENT_DOCUMENT_TYPE,
    R_CONTRACT_TYPE
  } from "../../../../utils/permissionReferenceConfig";
  
  
  // Client Document Type
  export const GET_ALL_CLIENT_DOCUMENT_TYPE = "/Client/GetAllClientDocumentTypes";
  export const ADD_CLIENT_DOCUMENT_TYPE = "/Client/AddClientDocumentType";
  export const UPDATE_CLIENT_DOCUMENT_TYPE = "/Client/UpdateClientDocumentType";
  export const DELETE_CLIENT_DOCUMENT_TYPE = "/Client/DeleteClientDocumentType";
  export const GET_CLIENT_DOCUMENT_TYPE_BY_ID = "/Client/GetClientDocumentTypeById";
  
  // contract type
  export const GET_ALL_CONTRACT_TYPE = "/ClientContractType/GetAllClientContractTypes";
  export const ADD_CONTRACT_TYPE = "/ClientContractType/AddClientContractType";
  export const UPDATE_CONTRACT_TYPE = "/ClientContractType/UpdateClientContractType";
  export const DELETE_CONTRACT_TYPE = "/ClientContractType/DeleteClientContractType";
  export const GET_CONTRACT_TYPE_BY_ID = "/ClientContractType/GetClientContractById";
  
  
  // Purpose: Configuration for the type settings page.
  export const typeSettingConfig = {
    "client-document-type": {
      typeLabel: "Client Document Type",
      typeKey: "documentTypeName",
      idKey: "clientDocumentTypeGuid",
      getApiPath: GET_ALL_CLIENT_DOCUMENT_TYPE,
      addApiPath: ADD_CLIENT_DOCUMENT_TYPE,
      deleteApiPath: DELETE_CLIENT_DOCUMENT_TYPE,
      updateApiPath: UPDATE_CLIENT_DOCUMENT_TYPE,
      getByIdApiPath: GET_CLIENT_DOCUMENT_TYPE_BY_ID,
      subModuleRef: R_CLIENT_DOCUMENT_TYPE,
      listFormName: "Client Document Type List",
      addFormName: "Add Client Document Type",
      perKey: "ClientDocumentType"
    },
    "contract-type": {
      typeLabel: "Contract Type",
      typeKey: "contractType",
      idKey: "clientContractTypeGuid",
      getApiPath: GET_ALL_CONTRACT_TYPE,
      addApiPath: ADD_CONTRACT_TYPE,
      deleteApiPath: DELETE_CONTRACT_TYPE,
      updateApiPath: UPDATE_CONTRACT_TYPE,
      getByIdApiPath: GET_CONTRACT_TYPE_BY_ID,
      subModuleRef: R_CONTRACT_TYPE,
      listFormName: "Contract Type LIst",
      addFormName: "Add Client Contract Type",
      perKey: "ClientContractType"
    }
  };
  