import React from "react";
import InputWrapper from "./InputWrapper";
import ReactQuill from "react-quill";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
export default function ProTextEditor({
    name,
    mode,
    label,
    rules,
    control,
    type = "textarea",
    fieldProps = {},
    size = DEFAULT_SIZE,
    startAdornment = null,
    isAddMode,
    disabled,
    ...rest
}) {
    const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
    if (!canView || (isAddMode && !canAdd)) return null;

    if (mode === "read" || (mode === "edit" && !canEdit)) return <ReadOnlyInput size={size} name={name} label={label} {...rest} />;

    const handleKeyDown = e => {
        if (type === "number" && (e.key === "e" || e.key === "E")) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <InputWrapper size={size}>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <ReactQuill
                        style={{ height: 'auto' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        label={label}
                        disabled={disabled}
                        variant="outlined"
                        fullWidth
                        type={type}
                        placeholder={`Type ${label}`}
                        onKeyDown={handleKeyDown}
                        InputProps={{ startAdornment }}
                        InputLabelProps={{ shrink: true }}
                        helperText={error?.message || null}
                        error={!!error}
                        {...fieldProps}
                    />
                )}
            />
        </InputWrapper>
    );
}

ProTextEditor.propTypes = {
    name: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["read", "edit"]).isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    startAdornment: PropTypes.node,
    control: PropTypes.object.isRequired,
    type: PropTypes.string,
    isAddMode: PropTypes.bool,
    fieldProps: PropTypes.object,
    permission: PropTypes.shape({
      canView: PropTypes.bool,
      canAdd: PropTypes.bool,
      canEdit: PropTypes.bool
    }),
    formValues: PropTypes.object,
    size: PropTypes.string,
    handleReadValueClick: PropTypes.func,
    disabled: PropTypes.bool,
  };
