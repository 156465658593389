import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { typeSettingConfig } from "./typeSettingConfig";
import { DETAULT_ROLE, EMPLOYEE_ROLE, STAUTES } from "../../../../utils/constant";
import { getApi, postApi, putApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { R_SETTINGS } from "../../../../utils/permissionReferenceConfig";
import usePermission from "../../../../utils/customHooks/usePermission";

export default function TypeSettingForm() {
  const { id, settingType } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [isSaving, setIsSaving] = useState(false);

  const [typeData, setTypeData] = useState({});
  const [mode, setMode] = useState(isAddMode ? "edit" : "read");
  const [status, setStatus] = useState(STAUTES.IDLE);

  const { handleSubmit, control, setValue } = useForm({ defaultValues: {} });
  const {
    idKey,
    perKey,
    typeKey,
    typeLabel,
    getByIdApiPath,
    addApiPath,
    subModuleRef,
    addFormName,
    updateApiPath,
    getWithPostMethod = true
  } = useMemo(() => typeSettingConfig[settingType] || {}, [settingType]);
  const { [addFormName]: formPermission } = usePermission(subModPermissionSelector(R_SETTINGS, subModuleRef), addFormName);
  const { canView, canAdd, fieldPerObj } = formPermission;
  const backAction = () => navigate(`/settings/${settingType}`);

  const getDetailById = useCallback(
    async (id) => {  
      setStatus(STAUTES.LOADING);
      const apiPath = `${getByIdApiPath}/${id}`;
      try {
        const response = await getApi(apiPath);
        if (!response || Object.keys(response).length === 0) {
          errorToast("No data found for this document type.");
          return;
        }
        setTypeData(response);
        setValue(typeKey, response[typeKey] || "");
      } catch (error) {
        errorToast("Failed to fetch data.");
      } finally {
        setStatus(STAUTES.IDLE);
      }
    },
    [setValue, typeKey, getByIdApiPath]
  );
  
  const onSubmit = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = { ...typeData, ...data };
    setStatus(STAUTES.LOADING);
    const apiMethod = isAddMode ? postApi : putApi;
    const apiPath = isAddMode ? addApiPath : updateApiPath;
    const { error } = await apiMethod(apiPath, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`${typeLabel} ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  const onEdit = () => setMode("edit");

  const onCancel = () => {
    if (isAddMode) backAction();
    else setMode("read");
  };

  useEffect(() => {
    if (!typeKey) navigate("/");
  }, [typeKey, navigate]);

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  if (!typeKey) return null;
  const hideEdit = typeKey === "roleTypeName" && (typeData[typeKey] === DETAULT_ROLE || typeData[typeKey] === EMPLOYEE_ROLE);
  return (
    <Layout status={status}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} ${typeLabel}`}
        backLabel={`Go Back to All ${typeLabel}`}
        beingEdited={mode === "edit"}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        hideEdit={hideEdit}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput
            name={typeKey}
            label={typeLabel}
            control={control}
            mode={mode}
            isAddMode={isAddMode}
            formValues={typeData}
            rules={{ required: `${typeLabel} is required!` }}
            handleReadValueClick={hideEdit ? null : onEdit}
            permission={fieldPerObj[perKey]}
          />
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
