import axios from "axios";
import { BASE_API_URI, BASE_API_V2_URI } from "../constant";

axios.defaults.baseURL = BASE_API_URI;

axios.interceptors.request.use(config => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    if (
      (response.status !== 200 && response.status !== 201) ||
      (Object.hasOwn(response.data || {}, "success") && !response.data.success) ||
      (Object.hasOwn(response.data || {}, "statusCode") && response.data.statusCode !== 200 && response.data.statusCode !== 201)
    ) {
      if (response.data?.message) {
        throw new Error(response.data.message);
      } else throw new Error(response);
    }

    if (typeof response.data === "string") {
      return response.data;
    }

    if (Object.hasOwn(response.data, "value")) {
      return { data: response.data.value, totalRecords: response.data["@odata.count"] ?? 0 };
    }

    if (Object.hasOwn(response.data, "data")) {
      return response.data;
    }

    return response.data;
  },
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
      return;
    } else if (error.response?.status === 500) {
      throw new Error("Oops! Something went wrong on our end. Please try again later.");
    }
    throw new Error(error);
  }
);

export const apiRequest = async ({ method, url, req, params }) => {
  try {
    axios.defaults.baseURL = BASE_API_URI;

    if (url.startsWith("[V2]")) {
      url = url.replace("[V2]", "");
      axios.defaults.baseURL = BASE_API_V2_URI;
    } else if (url.startsWith("[ReportAPI]")) {
      url = url.replace("[ReportAPI]", "");
      axios.defaults.baseURL = BASE_STIMULSOFT_URL;
    }

    if (method === "get") return await axios[method](url, params);
    return await axios[method](url, req, params);
  } catch (error) {
    return { error: error.message };
  }
};
export const getApi = async (url, params) => await apiRequest({ method: "get", url, params });
export const postApi = async (url, req, params) => await apiRequest({ method: "post", url, req, params });
export const putApi = async (url, req, params) => await apiRequest({ method: "put", url, req, params });
export const deleteApi = async (url, req, params) => await apiRequest({ method: "delete", url, req, params });
export const patchApi = async (url, req, params) => await apiRequest({ method: "patch", url, req, params });
